.wrapper {
  position: fixed;
  top: 0;
  right: 0;

  .icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .content {
    position: absolute;
    background-color: #fff;
    top: 50px;
    right: 16px;
    width: calc(100vw - 32px);
    box-sizing: border-box;
    padding: 32px;
    max-width: 400px;
    border-radius: 32px;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: scroll;
    transition: 0.2s all linear;

    &.hidden {
      width: 0;
      padding: 0;
    }
  }
}
